<template>
	<div>
		<a @click="showComment">Comment</a>
		<a-modal v-drag-modal v-model="commentsModal.show" title="Comment" :footer="null" width="800px" @ok="sendContent">
			<a-row slot="title" class="comments-title">
				<a-col :span="16"> {{ $t('table.loanReview.comments') }} </a-col>
				<a-col :span="8" style="text-align: right">
					<a-icon @click="commentsModal.show = false" type="close" />
				</a-col>
			</a-row>
			<div class="comments-content comments-modal">
				<div class="comments-list">
					<a-row v-for="item in commentsModal.commentList" :key="item.id">
						<a-col :span="12" class="name">{{ item.username }}</a-col>
						<a-col :span="12" class="time">{{ item.commentTime }}</a-col>
						<a-col :span="24" class="content">{{ item.comment }}</a-col>
					</a-row>
				</div>
				<a-row>
					<a-input type="textarea" v-model="commentsModal.comment" :rows="4" :maxLength="300" />
				</a-row>
				<a-row style="text-align: right">
					<a-button type="primary" class="send-btn" @click="sendContent">Send</a-button>
				</a-row>
			</div>
		</a-modal>
	</div>
</template>
<script>
import { apiCommentSave, apiCommentList } from '@/api/collections'
export default {
	props: ['recordId', 'loanId'],
	data() {
		return {
			commentsModal: {
				show: false,
				comment: '',
				commentList: []
			}
		}
	},
	methods: {
		showComment() {
			this.commentsModal.show = true
			this.commentsModal.comment = ''
			apiCommentList({
				recordId: this.recordId
			}).then((res) => {
				this.commentsModal.commentList = res
			})
		},
		sendContent() {
			if (this.$Util.isEmpty(this.commentsModal.comment)) {
				this.$message.error("Comments can't null")
				return false
			}
			apiCommentSave({
				loanId: this.loanId,
				recordId: this.recordId,
				comment: this.commentsModal.comment
			}).then(() => {
				this.commentsModal.content = ''
				this.commentsModal.show = false
				this.initCommentList()
			})
		}
	}
}
</script>
<style lang="less">
.comments-modal {
	width: 100%;
}
</style>
