<template>
	<div>
		<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
			<template slot="btn">
				<a-button type="primary" @click="exportSheet">
					{{ $t('page.download') }}
				</a-button>
			</template>
			<template slot="action" slot-scope="{ columnData }">
				<a @click="showEditEmailsModal(columnData)">Edit Emails</a>
				<a-divider type="vertical" />
				<a @click="getApiDemandLetterParam(columnData)"> Download Sample </a>
				<a-divider type="vertical" />
				<a @click="showRecordModal(columnData)"> Record </a>
				<a-divider type="vertical" />
				<a @click="showSendEmailsModal(columnData)">Send Demand Letter</a>
				<a-divider type="vertical" />
				<a @click="Detail(columnData.loanId)">{{ $t('page.checkDetail') }}</a>
			</template>
			<template slot="scopedSlots" slot-scope="{ columnData }">
				<span v-for="item in columnData.emails" :key="item"> {{ item }}<br /> </span>
			</template>
			<template slot="scopedSlots2" slot-scope="{ columnData }">
				<a @click="getEmailLog(columnData)">Details</a> <a-tag color="blue" v-if="todaySent(columnData)">Sent</a-tag>
			</template>
		</search-list-table>
		<a-modal v-drag-modal v-model="editEmailsModalShow" title="Edit Email" :destroyOnClose="true" width="400px" @ok="sendEditEmails">
			<div v-if="editEmails.length > 0">
				<div v-for="(mobile, index) in editEmails" :key="index" style="margin-bottom: 5px">
					<a-input class="before-input" v-model="editEmails[index]" style="width: 100%">
						<a-icon slot="addonAfter" type="minus" v-if="editEmails.length > 1" @click="editEmails.splice(index, 1)" />
					</a-input>
				</div>
				<a-button type="primary" block icon="plus" v-if="editEmails.length < 3" @click="editEmails.push('')" />
			</div>
		</a-modal>
		<a-modal v-drag-modal v-model="sendEmailsModalShow" title="Demand Letter" width="800px" @ok="sendEmail" :afterClose="afterCloseModal">
			<UploadPreviewFile
				ref="uploadPreviewFileRef"
				:uploadFilePath.sync="demandLetterFile"
				:folderPath="'collections/demandLetter'"
				:format="'.pdf'"
				:callbackFun="getFilePath"
			>
			</UploadPreviewFile>
		</a-modal>
		<a-modal v-drag-modal v-model="recordModalShow" title="Record" :destroyOnClose="true" width="1000px" @ok="addRecord">
			<a-tabs default-active-key="1">
				<a-tab-pane key="1" tab="Add Record">
					<a-form-item label="Please select the loan stage and Excution Time below：" htmlFor="div">
						<a-select style="width: 300px" v-model="recordModal.status">
							<a-select-option
								v-for="status in ['bc_demand_letter', 'auctioneer_demand_letter', 'repossession_start', 'advertise_selling', 'auctioneer_sells_car']"
								:key="status"
							>
								{{ $t(`table.recordStatusMap.${status}`) }}
							</a-select-option>
						</a-select>
						<a-date-picker style="width: 200px; margin-left: 10px" v-model="recordModal.date" />
					</a-form-item>
					<a-form-item label="Upload File (optional)" htmlFor="div">
						<UploadPreviewFile :uploadFilePath.sync="recordModal.files" :folderPath="loanId" :listLength="3"></UploadPreviewFile>
					</a-form-item>
					<a-form-item label="Remarks (optional)" htmlFor="div">
						<a-textarea v-model="recordModal.remarks" :maxLength="300" style="width: 400px" :auto-size="{ minRows: 4 }" />
					</a-form-item>
				</a-tab-pane>
				<a-tab-pane key="2" tab="Record History" force-render>
					<a-table :columns="recordColumns" size="small" :rowKey="(record, index) => index" :dataSource="recordData" bordered :pagination="false">
						<template slot="files_render" slot-scope="columnData">
							<span v-for="filePath in columnData.files" :key="filePath">
								<a :href="$Util.getObsImageUrl($store.state.pageState.authObj, filePath)" class="file_name" target="_blank">
									{{ !$Util.isEmpty(filePath) ? filePath.split('/').at(-1) : '' }} </a
								><br />
							</span>
						</template>
						<template slot="action" slot-scope="columnData">
							<comments-modal :recordId="columnData.id" :loanId="loanId">Comment</comments-modal>
						</template>
					</a-table>
				</a-tab-pane>
			</a-tabs>
		</a-modal>
		<a-modal v-model="recordHistoryModalShow" :footer="false" title="Records" :destroyOnClose="true" width="800px">
			<a-table
				v-if="recordHistoryData"
				style="font-size: 12px"
				:columns="recordHistoryColumns"
				size="small"
				:rowKey="(record, index) => index"
				:dataSource="recordHistoryData"
				:pagination="pagination"
				bordered
			>
				<template slot="files_render" slot-scope="columnData">
					<a :href="columnData.file" class="file_name" target="_blank"> {{ columnData.file }} </a><br /> </template
			></a-table>
		</a-modal>
	</div>
</template>
<script>
import {
	apiDemandLetterPage,
	apiDemandLetterEmail,
	apiDemandLetterParam,
	apiDemandLetterUpload,
	apiSendEmail,
	apiRecordSave,
	apiRecordList,
	apiDemandLetterData
} from '@/api/collections'
import { exportWordDocx } from '@/utils/exportWordDocx'
import { apiBranchList } from '@/api/branch'
import moment from 'moment'
import CommentsModal from '@/views/collectionsManagement/components/CommentsModal'
export default {
	data() {
		return {
			branchOfficeList: [],
			sendEmailsModalShow: false,
			editEmailsModalShow: false,
			loanId: '',
			editEmails: [],
			demandLetterFile: '',
			demandLetterFileId: '',
			recordModalShow: false,
			recordModal: {
				status: '', //逾期状态
				date: '', //状态日期
				files: [], //文件
				remarks: '' //备注
			},
			recordData: [],
			recordHistoryModalShow: false,
			recordHistoryData: [],
			pagination: {
				total: 0,
				showTotal: (total) => `Total ${total} items`,
				hideOnSinglePage: false,
				current: 1,
				showSizeChanger: true,
				pageSize: 10,
				pageSizeOptions: ['10', '20', '50', '100']
			}
		}
	},
	components: {
		CommentsModal
	},
	computed: {
		formSearchList() {
			return [
				// {
				// 	label: 'table.applyInfo.cloums.caseId',
				// 	type: 'input',
				// 	validateField: ['caseId']
				// },
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['clientName']
				},
				{
					label: 'table.applyInfo.applicationLabel.cloums.mobile',
					type: 'input',
					validateField: ['mobile']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.finance.cloum.status',
					type: 'select',
					validateField: ['status'],
					selectOption: [
						'bc_demand_letter',
						'auctioneer_demand_letter',
						'repossession_start',
						'advertise_selling',
						'auctioneer_sells_car',
						'overdue'
					],
					prefix: 'table.recordStatusMap'
				},
				{
					label: 'table.finance.cloum.defaultDate',
					type: 'range-picker',
					validateField: ['dueDate', { initialValue: [] }]
				},
				{
					label: 'table.finance.cloumRepayment.shouldDate',
					type: 'range-picker',
					validateField: ['scheduledDate', { initialValue: [] }]
				},
				{
					label: 'DPD',
					type: 'scope-input-number',
					validateField: [['dueDaysBegin'], ['dueDaysEnd']]
				},
				{
					label: 'Due Date',
					type: 'scope-input-number',
					validateField: [['dueDayBegin'], ['dueDayEnd']]
				},
				{
					label: 'table.applyInfo.loanApplyLabel.branchOffice',
					type: 'select',
					validateField: ['officeId'],
					selectOption: this.branchOfficeList,
					dataPreType: {
						value: 'id',
						text: 'storeName'
					}
				}
			]
		},
		columns() {
			return [
				{
					// 案件ID
					title: 'No.',
					scopedSlots: { customRender: 'serial_number' },
					align: 'center',
					width: 80,
					fixed: 'left'
				},
				{
					// 案件ID
					title: this.$t('table.applyInfo.cloums.caseId'),
					dataIndex: 'loanId',
					align: 'center',
					width: 150,
					fixed: 'left',
					customRender: (v, o) => {
						return o.casePrefix + '-' + o.caseId
					}
				},
				{
					// 用户名
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'fullName',
					align: 'center'
				},
				{
					// 手机号
					title: this.$t('table.userFrom.columns.mobile'),
					dataIndex: 'mobiles',
					align: 'center',
					customRender: (v, o) => (v ? v.join('/') : '')
				},
				{
					title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
					align: 'center',
					dataIndex: 'carInfos',
					customRender: (v, o) =>
						v
							? v
									.map((item) => item.registrationNumber)
									.filter(Boolean)
									.join('/')
							: ''
				},
				{
					title: 'DPD',
					align: 'center',
					dataIndex: 'dpd'
				},
				{
					title: this.$t('table.finance.cloum.defaultAmount'),
					align: 'center',
					dataIndex: 'overdueAmount',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					title: this.$t('table.finance.cloum.defaultDate'),
					align: 'center',
					dataIndex: 'dueDate',
					customRender: (v, o) => moment(v).format('YYYY-MM-DD')
				},
				{
					title: this.$t('table.finance.cloumRepayment.should'),
					align: 'center',
					dataIndex: 'scheduleRepayment',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					title: this.$t('table.finance.cloumRepayment.shouldDate'),
					align: 'center',
					dataIndex: 'scheduleDate',
					customRender: (v, o) => moment(v).format('YYYY-MM-DD')
				},
				{
					title: this.$t('table.Permission.User.email'),
					scopedSlots: { customRender: 'scopedSlots' },
					align: 'center'
				},
				{
					title: 'Sending History',
					scopedSlots: { customRender: 'scopedSlots2' },
					align: 'center'
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 290,
					fixed: 'right'
				}
			]
		},
		recordColumns() {
			return [
				{
					title: 'Excution Time',
					dataIndex: 'date',
					align: 'center'
				},
				{
					title: 'Status',
					dataIndex: 'status',
					align: 'center',
					customRender: (v, o) => (v ? this.$t(`table.recordStatusMap.${v}`) : '')
				},
				{
					title: 'File',
					align: 'center',
					scopedSlots: { customRender: 'files_render' }
				},
				{
					title: 'Remarks',
					align: 'center',
					width: 200,
					dataIndex: 'remarks'
				},
				{
					title: 'Operator',
					align: 'center',
					dataIndex: 'username'
				},
				{
					title: 'Function',
					align: 'center',
					scopedSlots: { customRender: 'action' }
				}
			]
		},
		recordHistoryColumns() {
			return [
				{
					title: 'Excution Time',
					dataIndex: 'sendTime',
					align: 'center'
				},
				{
					title: 'File',
					align: 'center',
					scopedSlots: { customRender: 'files_render' }
				},
				{
					title: 'Operator',
					align: 'center',
					dataIndex: 'username'
				}
			]
		}
	},
	methods: {
		moment,
		initDataSource(initParams) {
			return apiDemandLetterPage(initParams)
		},
		exportWordDocx(demandLetterData) {
			const basePrefix = process.env.NODE_ENV === 'development' ? `/bccms/` : `/`
			exportWordDocx(
				`${basePrefix}demandLetter.docx`,
				{
					OurRef: demandLetterData.carInfos
						.map((item) => this.$loanMap.loanType[demandLetterData.loanType].prefixType + item.registrationNumber)
						.join(' ,'),
					FullName: demandLetterData.clientName,
					IdNo: demandLetterData.identityCardNo,
					CityName: demandLetterData.cityName,
					DownloadDate: moment().format('Do MMMM YYYY'),
					Tel: demandLetterData.mobiles.join(' ,'),
					Re:
						demandLetterData.clientName +
						' LOAN ARREARS BALANCE AS AT ' +
						moment().format('Do MMMM YYYY') +
						' KES ' +
						demandLetterData.scheduleRepayment
							.toFixed(2)
							.toString()
							.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
					PrincipalLoan: demandLetterData.loanAmount
						.toFixed(2)
						.toString()
						.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
					DisbursedDate: moment(demandLetterData.disburseDate).format('Do MMMM YYYY') + ' to be paid in ' + demandLetterData.period,
					InstallmentKes:
						demandLetterData.currentInstallment
							.toFixed(2)
							.toString()
							.replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
						' falls due on ' +
						moment(demandLetterData.currentDueDate).format('Do') +
						' every month for the next ' +
						demandLetterData.remainInstallments +
						' months.You have not paid ' +
						demandLetterData.dueDates.map((item) => moment(item).format('MMMM') + ' ' + moment(item).format('YYYY') + ' installment').join(',')
				},
				`${demandLetterData.casePrefix + '-' + demandLetterData.caseId} Demand Letter.docx`
			)
		},
		getApiDemandLetterParam(columnData) {
			apiDemandLetterParam({
				loanId: columnData.loanId
			}).then((res) => {
				this.exportWordDocx({ ...res, carInfos: columnData.carInfos, casePrefix: columnData.casePrefix, caseId: columnData.caseId })
			})
		},
		showEditEmailsModal(columnData) {
			this.loanId = columnData.loanId
			this.editEmails = columnData.emails ? columnData.emails : ['']
			this.editEmailsModalShow = true
		},
		sendEditEmails() {
			apiDemandLetterEmail({
				loanId: this.loanId,
				emails: this.editEmails
			}).then(() => {
				this.$message.success('Success')
				this.editEmailsModalShow = false
				this.$refs.searchListTable.initTable(1)
			})
		},
		getFilePath(type) {
			apiDemandLetterUpload({ filepath: type, loanId: this.loanId }).then((res) => {
				this.demandLetterFileId = res
				this.$message.success('Success')
			})
		},
		showSendEmailsModal(columnData) {
			if (!columnData.emails) {
				this.$message.error("Please input the client's email first")
				return false
			}
			this.loanId = columnData.loanId
			this.editEmails = columnData.emails ? columnData.emails : ['']
			this.sendEmailsModalShow = true
		},
		afterCloseModal() {
			this.$refs.uploadPreviewFileRef.updateUploadFilePathList()
			this.demandLetterFile = ''
		},
		sendEmail() {
			if (!this.editEmails) {
				this.$message.error("Please input the client's email first")
				return false
			}
			if (!this.demandLetterFileId) {
				this.$message.error('Please Upload File First')
				return false
			}
			this.$confirm({
				title: '',
				content: `Are sure to send the Demand Letter to ${this.editEmails.join(',')}`,
				onOk: () => {
					apiSendEmail({
						id: this.demandLetterFileId,
						emails: this.editEmails,
						loanId: this.loanId
					})
						.then(() => {
							this.$message.success('Success')
							this.sendEmailsModalShow = false
							this.$refs.searchListTable.initTable(1)
						})
						.catch((err) => {})
				},
				onCancel() {}
			})
		},
		showRecordModal(columnData) {
			this.recordModalShow = true
			this.recordModal = {
				status: '',
				date: '',
				files: [],
				remarks: ''
			}
			this.loanId = columnData.loanId
			apiRecordList({
				loanId: columnData.loanId
			}).then((result) => {
				this.recordData = result
			})
		},
		addRecord() {
			console.log(this.recordModal.status)
			console.log(this.recordModal.date)
			if (!this.recordModal.status || !this.recordModal.date) {
				this.$message.error('Please select the loan stage and Excution Time below')
				return false
			}
			this.$confirm({
				title: '',
				content: this.$t('table.applyInfo.msgMap.msg1'),
				onOk: () => {
					apiRecordSave({
						loanId: this.loanId, //案件id
						status: this.recordModal.status, //逾期状态
						date: moment(this.recordModal.date).format('YYYY-MM-DD'), //状态日期
						files: this.recordModal.files, //文件
						remarks: this.recordModal.remarks //备注
					}).then(() => {
						this.$message.success('Success')
						this.recordModalShow = false
					})
				},
				onCancel() {}
			})
		},
		Detail(id) {
			this.$router.push({
				name: 'menus.customerManagementMenu.lendingdetail',
				query: {
					id
				}
			})
		},
		getEmailLog(columnData) {
			this.recordHistoryModalShow = true
			this.recordHistoryData = columnData.emailLogs ? columnData.emailLogs : []
		},
		todaySent(columnData) {
			return moment(moment(columnData.emailLogs && columnData.emailLogs[0].sendTime).format('YYYY-MM-DD')).isSame(
				moment(new Date()).format('YYYY-MM-DD')
			)
		},
		exportSheet() {
			const searchForm = { ...this.$refs.searchListTable.searchFormTable.getFieldsValue() }
			searchForm.scheduledDateBegin = searchForm.scheduledDate[0] && searchForm.scheduledDate[0].format('YYYY-MM-DD') + ' 00:00:00'
			searchForm.scheduledDateEnd = searchForm.scheduledDate[1] && searchForm.scheduledDate[1].format('YYYY-MM-DD') + ' 00:00:00'
			searchForm.dueDateBegin = searchForm.dueDate[0] && searchForm.dueDate[0].format('YYYY-MM-DD') + ' 00:00:00'
			searchForm.dueDateEnd = searchForm.dueDate[1] && searchForm.dueDate[1].format('YYYY-MM-DD') + ' 00:00:00'
			delete searchForm.scheduledDate
			delete searchForm.dueDate
			apiDemandLetterData(searchForm).then((res) => {
				let str = `Case ID,Client Name,Registration No.,Mobile No.,DPD,Default Amount,Default Date,Scheduled Repayment,Scheduled Date,Email`
				res.forEach((item) => {
					const registrationNumbers = item.carInfos ? item.carInfos.map((reg) => reg.registrationNumber) : null
					str += `\r\n${item.casePrefix}-${item.caseId},${item.fullName},${item.mobiles ? item.mobiles.join('/') : '-'},${
						registrationNumbers ? registrationNumbers.join('/') : '-'
					},${item.dpd},${item.overdueAmount},${item.dueDate},${item.scheduleRepayment},${item.scheduleDate},${
						item.emails ? item.emails.join('/') : '-'
					}`
				})
				//encodeURIComponent解决中文乱码
				let uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str)
				let link = document.createElement('a')
				link.href = uri
				link.download = `Overdue.csv`
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			})
		}
	},
	created() {
		this.$nextTick(() => {
			apiBranchList().then((res) => {
				this.branchOfficeList = res
			})
		})
	}
}
</script>
<style lang="less">
.send {
	display: flex;
	margin-top: 20px;
	.ant-form-item-label {
		font-weight: 600;
	}
	.ant-input,
	.ant-select {
		width: 360px;
		height: 40px;
		line-height: 40px;
	}
}
</style>
